<script setup lang="ts">
import { buildUrlPrefix, getProductRoute } from "@shopware/helpers";
import { ref, computed, unref } from "vue";
import type { ComputedRef } from "vue";
import { useProductConfigurator, useProduct } from "#imports";
import { useRouter } from "vue-router";
import type { Schemas } from "#shopware";
import { getSizesObject } from "~/components/utilities";
import getUrlPrefix from "~/components/cms/helpers/getUrlPrefix";
import { useCachedProduct } from "~/components/server/useCachedProduct";

const prefix = getUrlPrefix();

const props = withDefaults(
  defineProps<{
    productId: string;
    isMain: boolean;
  }>(),
  {}
);

const productResponse = ref();
productResponse.value = await useCachedProduct(props.productId);
const { product } = useProduct(
  productResponse.value.product,
  productResponse.value.configurator
);

// const { changeVariant } = useProduct();

const emit = defineEmits<{
  (e: "change", selected: Partial<Schemas["Product"]> | undefined): void;
}>();

const isLoading = ref(true);
const router = useRouter();
const {
  handleChange,
  getOptionGroups,
  getSelectedOptions,
  findVariantForSelectedOptions,
} = useProductConfigurator();

await getSizesObject(getOptionGroups, props.productId);

const selectedOptions: ComputedRef = computed(() =>
  Object.values(unref(getSelectedOptions))
);
const isOptionSelected = (optionId: string) =>
  Object.values(getSelectedOptions.value).includes(optionId);

const onHandleChange = async () => {
  let queryOptions = unref(selectedOptions);
  if (queryOptions.length > 1) {
    queryOptions.shift();
  }

  const variantFound = await findVariantForSelectedOptions(
    unref(selectedOptions)
  );

  emit("change", variantFound);
};

const sortByPosition = (options: any) => {
  let newOptions = Object.assign([], options);
  return newOptions.sort((a: any, b: any) =>
    a.position > b.position ? 1 : -1
  );
};
onMounted(() => {
  onHandleChange();
  isLoading.value = false;
});
</script>

<template>
  <div class="add-to-cart-selection">
    <div
      v-if="getOptionGroups && !isLoading"
      v-for="optionGroup in getOptionGroups"
      :key="optionGroup.id"
      class="mb-5"
    >
      <h5 class="mb-[10px] normal-case flex items-center justify-between">
        {{ optionGroup.name }}
      </h5>
      <div class="flex flex-wrap gap-2 color-box-wrap mb-5">
        <div
          class="w-12"
          v-for="option in sortByPosition(optionGroup.options)"
          :key="option.id"
          data-testid="product-variant"
        >
          <label
            class="aspect-square text-sm border border-solid border-black03 relative before:border-pink flex items-center justify-center cursor-pointer"
            :class="{
              'border-3 border-pink bg-cream': isOptionSelected(option.id),
              'out-of-stock': option.stock === 0,
            }"
            @click="handleChange(optionGroup.name, option.id, onHandleChange)"
          >
            <p
              :id="`${option.id}-choice-label`"
              data-testid="product-variant-text"
            >
              {{ option.name }}
            </p>
          </label>
        </div>
      </div>
    </div>
    <i v-else class="fa-solid fa-spinner animate-spin"></i>

  </div>
</template>
